






















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import eventBus from "@/utilities/EventBus";

@Component({})
export default class ApiButton extends Vue {

    @Prop({ default: null }) private busyEventName!: string|null;

    mounted(): void {
        if(this.busyEventName != null) eventBus.$on(this.busyEventName, (isBusy: boolean) => {
            this.loading = isBusy;
        });
    }

    @Prop({ default: "primary" }) private color!: string;
    @Prop({ default: "white" }) private textColor!: string;
    @Prop({ default: false }) private disabled?: boolean;
    @Prop({ default: false }) private outlined!: boolean;
    @Prop({ default: false }) private large!: boolean;
    @Prop({ default: false }) private small!: boolean;
    @Prop({ default: false }) private block!: boolean;
    @Prop({ default: false }) private icon?: boolean;
    @Prop({ default: false }) private dark?: boolean;
    @Prop({ default: false }) private text?: boolean;
    @Prop({ default: false }) private fab!: boolean;
    @Prop({ default: null }) private to?: string;
    
    loading: boolean = false;

    /* eslint-disable @typescript-eslint/ban-types */
    get listeners(): Record<string, Function | Function[]> {
        // we could add in our own custom listeners here
        return { ...this.$listeners };
    }
}
