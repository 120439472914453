// Same as TriState in Visual Basic
export enum TriState {
    UseDefault = -2, // 0xFFFFFFFE
    True = -1, // 0xFFFFFFFF
    False = 0
}

export enum UserRole {
    None = 0, // not signed in
    SuperAdmin = 1,
    Admin = 2,
    Supplier = 3,
    Printer = 4,
    Retailer = 5
}

export enum UserStatus {
    None = 0,
    InitialEmailPending = 1,
    InitialEmailSent = 2,
    InitialEmailFailed = 3,
    EmailConfirmed = 4, // means they followed the initial email link
    Active = 5 // means they set a password
}

export enum LookupGroup {
    All = 0,
    ReadyForDispatchReason = 1
}

export enum ReportInputType {
    Unknown = 0,
    DatePicker = 1,
    Dropdown = 2,
    TextField = 3,
}