/* eslint-disable  @typescript-eslint/no-explicit-any */
import Vue from "vue";
import Vuex, { StoreOptions, MutationTree, ActionTree, ActionContext } from "vuex";
import utils from "@/utilities/Utils";
import { User } from "@/model/User";

Vue.use(Vuex);

//
// -- This allows us to keep globally available data
//    that we can bind to in components...
//

// Define what we're storing
export interface IStoreState {
    signedInUser: User | null;
    pendingApiCall: Promise<any> | null;
}

//
// -- build an options object
//

const options: StoreOptions<IStoreState> = { 
    state: () => ({
        // shared state
        signedInUser: null as User|null,
        pendingApiCall: null
    }),
    getters: {
        // computed state
        isSignedIn: (state: IStoreState) => state.signedInUser !== null && !utils.isEmptyId(state.signedInUser.id),
        signedInUserName: (state: IStoreState) => (state.signedInUser !== null && !utils.isEmptyId(state.signedInUser.id)) ? state.signedInUser.fullname : "(not signed in)"
    },
    mutations: {},  // added below
    actions: {},    // added below
    modules: {}     // guess we don't need this...
};

// couple of shortcuts (and tell subsequent typescript they're *not* undefined)
const mutations = options.mutations as MutationTree<IStoreState>;
const actions = options.actions as ActionTree<IStoreState, IStoreState>;

// used during signing back in
mutations.setPendingApiCall = (state: IStoreState, pendingApiCall: Promise<any>) => {
    state.pendingApiCall = pendingApiCall;
};

actions.setPendingApiCall = (context: ActionContext<IStoreState, IStoreState>, pendingApiCall: Promise<any>) => {
    console.log("STORE - action - setPendingApiCall");
    context.commit("setPendingApiCall", pendingApiCall);
};

// the current user - see @/utilities/Authentication module
mutations.setSignedInUser = (state: IStoreState, user: User) => {
    state.signedInUser = user;
};

actions.loadSignedInUser = (context: ActionContext<IStoreState, IStoreState>) => {
    // try and retrieve from session storage
    const userJson = sessionStorage.getItem("signedInUser");
    if(!userJson) return;
    const user = new User(JSON.parse(userJson as string));
    context.commit("setSignedInUser", user);
};

actions.setSignedInUser = (context: ActionContext<IStoreState, IStoreState>, user: User) => {
    sessionStorage.setItem("signedInUser", JSON.stringify(user));
    context.commit("setSignedInUser", user);
};

//
// -- the main deal - export the Store
//

export default new Vuex.Store<IStoreState>(options);
