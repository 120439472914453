


















import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class VisitorLayout extends Vue {
    links: Array<string> = ["Link 1", "Link 2"];
}
