// We need a global, single value flag that we can check in window.onbeforeunload and beforeRouteLeave
// BUT we also need to be able to bind to a reactive property within the components!
// We can't really use Vuex (store) becuase that needs access to a component so the only
// way I can see is to have TWO flags - one here and one as a property on the current
// component, both with the same value :-(

// TODO - think up a better way!?

let myIsDirty = false;
let cleanFingerprint: Array<any> = [];

//let fingerprint: string = "";

export interface IDirtynessOptions {
    model: any;
    isDeep: boolean;
    excludeList: Array<string>;
}

// We could replace JSON with lodash.deepClone and lodash.isEqual if we need something more robust...
// ...JSON ignores functions (fine), is sensitive to order of properties (fine) but won't work for circular references, etc.
function buildFingerprint(model: any, isDeep: boolean, excludeList: Array<string>, values: Array<any>)  {
    if(model == null) return "";

    for (const key in model) {
        if (model.hasOwnProperty(key) && excludeList.indexOf(key) === -1) {
            const item = model[key];
            if(item == null) {
                //values.push(key + "=NULL");
                values.push(null);
            }
            else if (isDeep && typeof item === "object" && key.substring(0, 1) !== "_") {
                buildFingerprint(item, isDeep, excludeList, values);
            }
            else if (Array.isArray(item)) {
                for (let i = 0; i < item.length; i++) {
                    buildFingerprint(item[i], isDeep, excludeList, values);
                }
            }
            else if(typeof item !== "object") {
                //values.push(key + "=" + item.toString());
                values.push(item);
            }
        }
    }
}

//  NB this will return FALSE if things are in wrong order, e.g. arrays.
function areFingerPrintsEqual(fp1: Array<any>, fp2: Array<any>): boolean {
    const len = fp1.length;
    if(fp2.length !== len) return false;
    for(let i = 0; i < len; i++) {
        if(fp1[i] !== fp2[i]) return false;
    }
    return true;
}

const dirtyness = {
    get isDirty(): boolean {
        return myIsDirty;
    },
    // call this after load or save 
    setClean(options: IDirtynessOptions): boolean {
        const fingerprint: Array<any> = [];
        buildFingerprint(options.model, options.isDeep, options.excludeList, fingerprint);
        cleanFingerprint = fingerprint;
        myIsDirty = false;
        return myIsDirty;
    },
    // call this whenever anything changes (use a watcher)
    dataChanged(options: IDirtynessOptions): boolean {
        const fingerprint: Array<any> = [];
        buildFingerprint(options.model, options.isDeep, options.excludeList, fingerprint);       
        myIsDirty = !areFingerPrintsEqual(fingerprint, cleanFingerprint);
        //console.log("current:", fingerprint);
        //console.log("clean  :", cleanFingerprint);
        return myIsDirty;
    },
    // call this when we navigate away and abandon changes
    reset(): boolean {
        cleanFingerprint = [];
        myIsDirty = false;
        return myIsDirty;
    },
    defaultAuditFieldList(): Array<string> {
        return ["created", "createdByUserID", "lastUpdated", "lastUpdatedByUserID", "deleted", "deletedByUserID"];
    }

};

export default dirtyness;