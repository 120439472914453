
















































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { UserRole } from "@/model/Enums";
import store from "@/store/store";
import authentication from "@/utilities/Authentication";

@Component({})
export default class SignedInLayout extends Vue {

    //
    // -- lifecycle hooks
    //

    mounted(): void {
        document.body.style.backgroundColor = "#fff";
    }

    //
    // -- computed properties
    //

    private get signedInUserName(): string {
        return store.getters.signedInUserName;
    }

    private get initials(): string {
        const parts = this.signedInUserName.split(" ");
        return parts.reduce((initials, part) => initials + part.substring(0, 1), "");
    }

    private get isSuperAdmin() {
        return store.state.signedInUser?.role == UserRole.SuperAdmin;
    }

    private get isAdmin() {
        return store.state.signedInUser?.role == UserRole.Admin || store.state.signedInUser?.role == UserRole.SuperAdmin;
    }

    private get isPrinter() {
        return store.state.signedInUser?.role == UserRole.Printer;
    }

    //
    // -- methods
    //

    signOut(): void {
        // Not using async / await here so we'll just trust the sign out occurs in the background
        // and rely on error handler if anything screws up.
        authentication.signOut();

        this.$router.push("/");
    }

}
