import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface ILookupItem {
    id: number;
    stringID: string;
    groupID: number;
    description: string;
    displayOrder: number;
    isArchived: boolean;
    created: Date;
    createdByUserID: string;
    lastUpdated: Date;
    lastUpdatedByUserID: string;
}

export class LookupItem implements ILookupItem {
    constructor(data?: ILookupItem) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: ILookupItem): void {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    stringID: string = "";
    groupID: number = 0;
    description: string = "";
    displayOrder: number = 0;
    isArchived: boolean = false;
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: string = utils.emptyGuidValue;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: string = utils.emptyGuidValue;

    get isNew(): boolean {
        return utils.isEmptyId(this.id);
    }

    static createLookup(id: number, description: string): LookupItem {
        return new LookupItem({
            id: id,
            stringID: "",
            groupID: 0,
            description: description,
            displayOrder: 0,
            isArchived: false,
            created: new Date(utils.emptyDateValue),
            createdByUserID: utils.emptyGuidValue,
            lastUpdated: new Date(utils.emptyDateValue),
            lastUpdatedByUserID: utils.emptyGuidValue
        });
    }
}