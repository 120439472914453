import Vue from "vue";
import VueRouter, { RouteConfig, NavigationGuard, Route, RawLocation } from "vue-router";
import store from "@/store/store";
import dirtyness from "@/utilities/Dirtyness";

Vue.use(VueRouter as any); // eslint-disable-line @typescript-eslint/no-explicit-any

let intendedUrl: string | null = null;

// call this in the *component* code (not here in the router setup)
export function beforeRouteLeave(to: Route, from: Route,  next: (to?: string | void | false) => void): void {
    if(dirtyness.isDirty && !confirm("Changes may not be saved.  Click cancel to stay on this page.")) {
        return next(false);
    }
    dirtyness.reset();
    return next();
}

const beforeEnterGuard: NavigationGuard = async (to: Route, from: Route, next: (to?: string | void) => void) => {

    //console.log(`### nav guard... signed in=${store.getters.isSignedIn} intendedUrl=${intendedUrl}`);

    if (store.getters.isSignedIn) {
        if (intendedUrl) {
            const url = intendedUrl;
            intendedUrl = null;
            console.log("### nav guard - now logged in - redirect");
            return next(url); // goto stored url
        } else {
            console.log("### nav guard - carry on...");
            return next(); // all is fine
        }
    }

    try {
        // get user from session storage
        await store.dispatch("loadSignedInUser");
    } catch (e) {
        console.log("@@@ loadSignedInUser failed ");
        console.error(e);
    }

    if (store.getters.isSignedIn) {
        console.log("### nav guard - got login ok");
        next();
    } else {
        intendedUrl = to.path; // store entry url before redirect
        console.log("### nav guard - redirect to signin");
        next("/signIn");
    }
};

//
// -- set up the routes
//

const routes: Array<RouteConfig> = [];

routes.push({ path: "/", name: "HomeSignIn", meta: { layout: "blank" }, component: () => import("@/vue/views/HomeSignIn.vue") });
routes.push({ path: "/Reset/:key", name: "ResetPassword", meta: { layout: "blank" }, component: () => import("@/vue/views/ResetPassword.vue") });
routes.push({ path: "/Activate/:key", name: "Activate", meta: { layout: "blank" }, component: () => import("@/vue/views/ResetPassword.vue") });

routes.push({ path: "/signin", name: "HomeSignIn", meta: { layout: "blank" }, component: () => import("@/vue/views/HomeSignIn.vue") });
routes.push({ path: "/Error", name: "Error500", meta: { layout: "visitor" }, component: () => import("@/vue/views/Error500.vue") });

routes.push({ path: "/Home", name: "AdminHome", meta: { layout: "admin" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/Home.vue") });
routes.push({ path: "/Profile", name: "Profile", meta: { layout: "admin" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/Profile.vue") });
routes.push({ path: "/users", name: "Users", meta: { layout: "admin" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/Users.vue") });
routes.push({ path: "/orders", name: "Orders", meta: { layout: "admin" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/Orders.vue") });
routes.push({ path: "/order/:orderID", name: "Order", meta: { layout: "admin" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/Order.vue")});
routes.push({ path: "/contracts", name: "Contracts", meta: { layout: "admin" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/Contracts.vue") });
routes.push({ path: "/suppliers", name: "Suppliers", meta: { layout: "admin" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/Suppliers.vue") });
routes.push({ path: "/printers", name: "Printers", meta: { layout: "admin" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/Printers.vue") });
routes.push({ path: "/languages", name: "Languages", meta: { layout: "admin" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/Languages.vue") });
routes.push({ path: "/products", name: "Products", meta: { layout: "admin" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/Products.vue") });
routes.push({ path: "/countries", name: "Countries", meta: { layout: "admin" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/Countries.vue") });
routes.push({ path: "/sizeOptions", name: "SizeOptions", meta: { layout: "admin" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/SizeOptions.vue") });
routes.push({ path: "/artworX", name: "ArtworX", meta: { layout: "admin" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/ArtworX.vue") });
routes.push({ path: "/artworXPreviewFields", name: "ArtworX Preview Fields", meta: { layout: "admin" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/ArtworXPreviewFields.vue") });
routes.push({ path: "/washcareSymbols", name: "Washcare Symbols", meta: { layout: "admin" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/WashcareSymbols.vue") });
routes.push({ path: "/exchangeRates", name: "Exchange Rates", meta: { layout: "admin" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/ExchangeRates.vue") });
routes.push({ path: "/reports", name: "Reports", meta: { layout: "admin" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/Reports.vue") });
routes.push({ path: "/lookups", name: "Lookups", meta: { layout: "admin" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/Lookups.vue") });

// This needs to be the **last** route
routes.push({ path: "*", meta: { layout: "visitor" }, component: () => import("@/vue/views/Error404.vue") });

//
// -- create the router
//

const router = new VueRouter({
    mode: "history",
    linkActiveClass: "active",
    linkExactActiveClass: "active",
    base: process.env.BASE_URL,
    routes
});

export function goToIntendedUrl(): void {
    if (!intendedUrl) router.replace("/contracts/"); // default in case not defined (?)
    router.replace(intendedUrl as RawLocation);
}

export default router;
