import utils from "@/utilities/Utils";
import { UserRole, UserStatus } from "@/model/Enums";
import { mapData } from "@/utilities/DataMapping";

export interface IUser {
    id: string;
    status: UserStatus;
    statusInfo: string;
    role: UserRole;
    forename: string;
    surname: string;
    email: string;
    orderConfirmationEmail: boolean;
    lastLoggedIn: Date;
    prevLastLoggedIn: Date;
    created: Date;
    createdByUserID: string;
    lastUpdated: Date;
    lastUpdatedByUserID: string;
    deleted: Date;
    deletedByUserID: string;
}

export class User implements IUser {

    constructor(data?: IUser) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IUser): void {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    status: UserStatus = UserStatus.None;
    statusInfo: string = "";
    role: UserRole = UserRole.None;
    forename: string = "";
    surname: string = "";
    email: string = "";
    orderConfirmationEmail: boolean = false;
    lastLoggedIn: Date = new Date(utils.emptyDateValue);
    prevLastLoggedIn: Date = new Date(utils.emptyDateValue);
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: string = utils.emptyGuidValue;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: string = utils.emptyGuidValue;
    deleted: Date = new Date(utils.emptyDateValue);
    deletedByUserID: string = utils.emptyGuidValue;
    jwtBearerToken: string = "";

    get isNew(): boolean {
        return utils.isEmptyId(this.id);
    }

    get isDeleted(): boolean {
        return utils.hasDateValue(this.deleted);
    }

    get fullname(): string {
        return this.forename + " " + this.surname;
    }
}